.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.launch {
  height: 600px;
  background-image: url("../src/Assets/launchPad-bg.png");
  background-position-x: -100px;
  background-repeat: no-repeat;
  width: 100vw;
  background-size: cover
    /* background: linear-gradient(90deg, #010400 0%, rgba(29, 13, 7, 0.6) 88.05%);; */
}

.scrollTop{
  position: fixed;
  top: 0;
}


.type::after {
  content: '|';
  font-weight: 200;
  animation: cursor 1.1s infinite step-start;
}



@keyframes cursor {
  50% {
    opacity: 0;
  }
}
.customMedia {
  left: 130px;
  top: 290px
}

@media screen and (min-height: 425px) {
  .customMedia {
    left: 130px;
    top: 290px
  }
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
